<template>
  <div class="box">
    <div class="top">
      <div class="logo"><img src="@/assets/img/logo.png" style="width:100%;height:100%" alt=""></div>
      <!-- @click="$router.push('/index')" -->
      <div class="baogao">我的營養素報告</div>
      <!-- <div class="shop" @click="upto()">營養品商城</div> -->
      <div class="flex">
        <div class="hend"><img src="~@/assets/img/hend.png" style="width:100%" alt=""></div>
        <div class="name">{{info.name}},您好</div>
        <div class="login" @click=" getout()">登出</div>
      </div>
    </div>
    <div class="vbvb">
      <div class="topv">
        <div class="one">夥伴管理介面</div>
        <div class="two">銷售報表</div>
        <div class="three" @click="$router.push('/nn')">我的顧客</div>
      </div>
      <div class="op">
        親愛的 <span style="font-size:23px;color:#000;font-weight: 550;">{{info.name}}</span> ,您好<br><br><br>

        <div style="  padding-left: 50px;letter-spacing:2px">目前您於此平台的消費金額為：<span style=" text-decoration:underline;">{{info.consumeAmount}}</span> ，當前會員等級是 <span style="text-decoration:underline;">{{info.levelName}}</span></div>
        <br>
        <div style="  ;padding-left: 450px;letter-spacing:2px">
          ，當前團隊身分是 <span style="text-decoration:underline;">{{info.identityName}}</span>
        </div><br><br>
        <!-- <div style="  padding-left: 50px;letter-spacing:2px"> 銷售額度：<span style="text-decoration:underline;">{{info.saleAmount}}</span></div> -->
      </div>
    </div>
    <div class="biaoge">

      <div class="sahgn">
        <div>
          <div class="brt">
            ● 近三月消費明細 ：
          </div>
          <div class="biaoge1">
            <el-table empty-text="系統統計中" :data="expensesRecord" border style="width: 100%;" :row-class-name="tableRowClassName" :header-cell-style="{background:'#00cccc', color:'#fff'}" :cell-style="rowStyle">
              <el-table-column prop="month" label="消費月份" width="150" align="center">
              </el-table-column>
              <el-table-column prop="amount" label="消費金額" width="180" align="center">
              </el-table-column>

            </el-table>
          </div>
        </div>

      </div>
      <br>
      <div class="asas"> 您的優惠代碼為：<span style="text-decoration:underline;">{{info.promotionCode}}</span>

      </div>
      <br>
      <div class="sahgn">
        <div>

          <div class="brt">
            ● 近三月銷售明細 ：
          </div>
          <div class="biaoge12">
            <el-table empty-text="系統統計中" :data="salesRecord" border style="width: 100%;" :row-class-name="tableRowClassName" :header-cell-style="{background:'#00cccc', color:'#fff'}" :cell-style="rowStyle">
              <el-table-column prop="month" label="銷售月份" width="150" align="center">
              </el-table-column>
              <el-table-column prop="promoCode" label="優惠碼" width="180" align="center">
              </el-table-column>
              <el-table-column prop="amount" label="銷售金額" width="180" align="center">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div>
          <div class="brt">
            ● 近三月銷售額 :
          </div>
          <div class="biaoge1">
            <el-table empty-text="系統統計中" :data="salesAmount" border style="width: 100%;" :row-class-name="tableRowClassName" :header-cell-style="{background:'#00cccc', color:'#fff'}" :cell-style="rowStyle">
              <el-table-column prop="month" label="銷售月份" width="150" align="center">
              </el-table-column>
              <el-table-column prop="amount" label="銷售金额" width="180" align="center">
              </el-table-column>

            </el-table>
          </div>
        </div>

      </div>
    </div>
    <div style="height:14vw">

    </div>
  </div>
</template>
<script>
//import Header from "../components/header"
import { logout } from "../../api/login.js"
import { getInfo, getbiao } from "../../api/infomtion.js"
import { removeToken } from "../../utils/auth.js"
export default {
  // components: { Header },
  data() {
    return {
      activeName: "second",
      info: {},
      salesRecord: [],
      salesAmount: [],
      expensesRecord: [],
      middle: "",
      tableData: [
        {
          date: "2016 / 05",
          name: "999,999",
        },
        {
          date: "2016 / 05",
          name: "999,999",
        },
        {
          date: "2016 / 05",
          name: "999,999",
        },
        {
          date: "2016 / 05",
          name: "999,999",
        },
      ],
    }
  },
  created() {
    this.getInfo()
    this.getbiao()
  },
  methods: {
    //表格内容居中
    rowStyle() {
      return "text-align:center"
    },
    tableRowClassName({ row, rowIndex }) {
      //  console.log("=========", row, rowIndex)

      if (row.month === this.middle) {
        return "warning-row"
      }
      // else if (row.month.charAt(row.month.length - 1) % 2 === 1) {
      //   return "success-row"
      // }
      return ""
    },
    handleClick(tab, event) {},
    upto() {
      window.location.href = "https://www.drnutri.tw/"
    },
    getInfo() {
      getInfo().then((res) => {
        //console.log("这是data", res.data)
        this.info = res.data
      })
    },
    getbiao() {
      getbiao().then((res) => {
        // console.log("expensesRecord", res.data)
        this.expensesRecord = res.data.expensesRecord //消费表格
        this.salesAmount = res.data.salesAmount //销售额
        this.salesRecord = res.data.salesRecord //销售表格
        let set_month = new Set(this.salesRecord.map((item) => item.month))

        console.log("middle", set_month)
        let str = Array.from(set_month)[1]

        this.middle = str + ""

        console.log("middle", this.middle)
      })
    },
    getout() {
      this.loading = true
      logout()
      this.$store
        .dispatch("LogOut")
        .then((res) => {
          this.$message({
            message: "退出登入成功",
            type: "success",
          })

          this.$router.push("/")
          removeToken(res.data)
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
/deep/.el-table .warning-row {
  background: #d0f4f4 !important;
}

/deep/.el-table .success-row {
  background: #d0f4f4 !important;
}
.brt {
  align-items: center;
}
.sahgn {
  width: 930px;
  display: flex;
  justify-content: space-between;
}
.asas {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #333333;
}
.biaoge {
  width: 800px;
  margin: 0 auto;
  .biaoge1 {
    //  width: 331px;
    // margin: 0 auto;
  }
  .biaoge12 {
    width: 511px;
    // margin: 0 auto;
  }
}
.topv {
  width: 1200px;
  height: 97px;

  //   background: rgb(198, 226, 187);
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 40px;
  .one {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    opacity: 1;
  }
  .two {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    border-bottom: 2px solid #000;
    margin-left: 580px;
  }
  .two:hover {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    margin-left: 580px;
    border-bottom: 2px solid #000;
  }
  .three {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 97px;
    color: #333333;
    margin-left: 60px;
  }
  .three:hover {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    margin-left: 60px;
    border-bottom: 2px solid #000;
  }
}
//报表
.op {
  width: 800px;
  height: 180px;
  margin: 0 auto;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #333333;
  margin: 0 auto;
  background: #fff;
}
//带状表格
/deep/.el-table .warning-row {
  background: oldlace;
}

/deep/.el-table .success-row {
  background: #f0f9eb;
}
//查看
/deep/.el-button {
  width: 62px;
  height: 30px;
  color: #fff;
  background: rgba(150, 185, 100, 0.39);
  opacity: 1;
  margin-left: 850px;
}
// .vbvb {
//   width: 1064px;
//   margin: 0 auto;
//   display: flex;
// }
//切换蓝
/deep/.el-tabs__item {
  font-size: 30px;
}
.count-chart-wrapper {
  //width: 800px;
  height: 450px;
  margin: 0 auto;
  background-color: #fff;
}
.count-chart {
  position: relative;
  margin: 0 auto;
  width: 1064px;
  height: 450px;
  canvas {
    width: 100%;
    height: 100%;
  }
}
.flex {
  //width: auto;
  display: flex;
  float: right;
  padding: 0 0;
  margin-right: 4%;
  margin-left: auto;
}
.box {
  width: auto;
  height: 1080px;
  min-width: 1300px;
  background: rgba(255, 255, 255, 0.39);
}
/* 顶部 */
.top {
  width: auto;
  height: 143px;
  min-width: 1300px;
  background: rgb(237, 244, 237);

  display: flex;
  align-items: center;
}
.logo {
  width: 405px;
  height: 143px;
  line-height: 0;
  margin: auto 0;
  margin-left: 30px;
  padding: 13px 0;
}
.baogao {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.baogao:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.shop {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #707070;
  // border-bottom: 3px solid #707070;
  margin-left: 5%;
}
.shop:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin-left: 5%;
}
.hend {
  width: 30px;
  height: 30px;
  line-height: 0;
}
.login {
  width: 44px;
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  margin-left: 10px;
}
.name {
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
}
.fist {
  width: 1140px;
  margin: 0 auto;
  .fistq {
    height: 50px;
    background: rgb(237, 244, 237);
    margin-top: 31px;
    div {
      height: 50px;
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;
      color: #333333;
      margin-left: 20px;
    }
  }
  .fistw {
    display: flex;
    justify-content: space-between;
    div {
      width: 48%;
    }
  }
  .fiste {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #333333;
    margin: 10px 20px;
    span {
      font-size: 18px;

      font-weight: bold;
      line-height: 25px;
      color: #333333;
    }
  }
  p {
    text-indent: 35px;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 10px;
    color: #333333;
  }
}
</style>>